<template>
    <div id="container"></div>
</template>

<script>
import {utlisApi} from "@/utils/utils";
//在需要使用的页面引入，并实例化对象
import CktDesign from "@chuangkit/chuangkit-design";


export default {
    name: "xhs",
    data() {
        return {
            openid: "",
            option: {},
            utlisApi: new utlisApi(),
            designTemplate: null,
        };
    },
    mounted() {
        this.init();
        window.chuangkitComplete = function (data) {
            console.log(data[`thumb-urls`][0], "下载文件");
            window.open(data[`thumb-urls`][0], '_blank');
        }
        console.log(this.option, "打印一下option");
        this.design = new CktDesign.createTemplateCenter(this.option);
        this.design.open().then(() => {
            this.design.onParamsChange(this.onParamsChange);
            this.design.onChooseTemplate(this.onChooseTemplate);

            // 关闭模板中心
            // instance.close();

            // 动态调整筛选项
            // @params { IParams } params 筛选项
            // instance.setParams(params)
        });
    },
    methods: {
        init() {
            let userinfo = window.localStorage.getItem('userInfo');
            userinfo = JSON.parse(userinfo);

            this.utlisApi.openid = userinfo.id;
            // 点击拉起
            const myparams = {
                settingCode: "CVDJ",
            };
            this.option = this.utlisApi.getDesignCenter(myparams);
            return this.option;
        },
        // 筛选项变更时触发---
        // @params { Iparams } params 筛选项
        onParamsChange(params) {
            console.log("筛选项变更时触发", params);
        },
        // 用户点击模板时触发---
        // @params { ITemplateItem } templateItem 模板项
        onChooseTemplate(templateItem) {
            // Todo: 判断用户是否有权限

            console.log(templateItem, "用户点击了模板");
            this.onUpEditor(templateItem.templateId);
        },
        /**
         * 点击使用模板，打开编辑器
         */
        async onUpEditor(templateId) {
            let isUse = await this.$utils.getaiCanUse();
            if (!isUse) {
                this.$message.error('您没有权限使用AI功能，请检查是否登录/小程序开通套餐');
                return;
            }
            let userinfo = window.localStorage.getItem('userInfo');
            userinfo = JSON.parse(userinfo);
            console.info(userinfo, isUse, "打印信息");
            // console.log(templateId, "点击使用模板，打开编辑器");
            this.utlisApi.openid = userinfo.id;
            // 点击拉起
            const myparams = {
                charge_type: 1,
                package_id: 225,
                coop_material_limit: 2,
                coop_font_limit: 2,
                charging_template_limit: 2,
                template_id: templateId, // 模板id
            };
            const option = this.utlisApi.getDesignEditor(myparams);
            console.log(option, "打印一下myparams");
            this.designTemplate = new CktDesign(option);
            this.designTemplate.open();
        },
    },
};
</script>

<style>
#container {
    width: 100%;
    height: calc(100vh - 100px);
}
</style>
